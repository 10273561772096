<template>
  <div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <LocationVariationMap
          :zone="tab"
          :location="location"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

  import LocationVariationMap from "@/components/location/LocationVariationMap"

  export default {
    name: 'Tables',
    components: {
      LocationVariationMap,
    },
    data: () => ({
      location: null
    }),
    methods: {
    },
    computed: {
      zones: function() {
        return this.$store.state.zones
      },
      tabs() {
        const tabs = []
        if(this.zones !== null && this.zones !== undefined && this.zones.length > 0) {
          for(let tabIndex in this.zones) {
            tabs.push({
              title: this.zones[tabIndex].name,
              key: this.zones[tabIndex].uuid
            })
          }
        }
        return tabs
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      }
    },
    beforeMount() {
      this.location = this.$store.state.locations.find(location => { return location.uuid === this.$route.params.location_uuid })
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)

      if(this.tabs.length > 0) {
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      }
    }
  }
</script>

<style scoped>
  .vue-grid-layout {
    background: #eebb11;
    touch-action: none
  }
  .vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
  }

</style>
